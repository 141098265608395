import React from "react";
import ArrowBtn from "../components/ArrowBtn";
import "./Home.css";

const Home = () => {
  return (
    <>
      <main>
        {/* Start preloader */}
        <div id="preloader" />
        {/* End preloader */}
        {/* Top scroll */}
        <ArrowBtn />
        {/* Top scroll End */}
        <header className="transition">
          <div className="container">
            <div className="row flex-align">
              <div className="col-lg-4 col-md-3 col-8">
                <div className="logo">
                  <a href="/index">
                    <img
                      src="assets/images/logo.png"
                      className="transition"
                      alt="Cryptcon"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-8 col-md-9 col-4 text-right">
                <div className="menu-toggle">
                  <span />
                </div>
                <div className="menu">
                  <ul className="d-inline-block">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="#about">About</a>
                    </li>
                    {/* <li><a href="#nft">NFT</a></li> */}
                    <li>
                      <a href="#tokensale-part">Token</a>
                    </li>
                    <li>
                      <a href="#roadmap">Roadmap</a>
                    </li>
                    <li>
                      <a
                        href="assets/Globaltradingswhitepaper.pdf"
                        target="blank"
                      >
                        Whitepaper
                      </a>
                    </li>
                  </ul>
                  {/* <div className="signin d-inline-block">
                    <a href="/" className="btn">
                      Sign in
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="home-banner" id="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 position-u flex-align wow fadeInLeft">
                <div className="banner-contain">
                  <h1 className="banner-heading">
                    Welcome to
                    <span> Globaltradings Coin </span> Empowering Financial
                    Freedom
                  </h1>
                  <p className="banner-des">
                    Globaltradings Coin (GTC COIN) is your gateway to a new era
                    of digital finance. Designed to offer lightning-fast,
                    low-cost transactions on the Binance Smart Chain,
                    Globaltradings Coin is changing the way you interact with
                    cryptocurrencies.
                  </p>
                  <a href="/" className="btn">
                    Learn more
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 position-u wow fadeInRight">
                <div className="banner-img">
                  <img src="assets/images/about-2.png" alt="banner" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="work-part lightskyblue ptb-100" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <label className="sub-heading">
                    PRODUCT BASED ON BLOCKCHAIN &amp; bsc chain
                  </label>
                  <h2 className="heading-title">About Us</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center flex-align justify-center wow fadeInLeft">
                <div className="work-box">
                  <div className="work-box-bg" />
                  <img
                    src="assets/images/work-process.png"
                    className="rotation-img"
                    alt="Work Process"
                  />
                </div>
              </div>
              <div className="col-md-6 flex-align wow fadeInRight">
                <div className="work-box">
                  <h3 className="work-process-title pb-25">
                    About block chain like bsc chain.
                  </h3>
                  <p className="work-des pb-20">
                    Blockchain technology has revolutionized various industries,
                    and one of the most notable developments is the emergence of
                    products
                  </p>
                  <ul className="check-list">
                    <li>
                      <span>
                        <i className="fa fa-check" aria-hidden="true" />
                      </span>{" "}
                      <p>
                        Decentralized exchanges with reduced transaction costs.
                        These products leverage the speed and efficiency of the
                        BSC network to offer users a seamless and
                        cost-effective.
                      </p>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-check" aria-hidden="true" />
                      </span>{" "}
                      <p>
                        Security and Privacy: Globaltradings Coin utilizes the
                        Binance Smart Chain's robust security features to ensure
                        your transactions are safe and private.
                      </p>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-check" aria-hidden="true" />
                      </span>{" "}
                      <p>
                        Discover the endless possibilities of Globaltradings
                        Coin. Get started today! and services built on the
                        Binance Smart Chain (BSC).
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature-part pt-100 pb-10">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <label className="sub-heading">OUR LATEST TECHNOLOGY &</label>
                  <h2 className="heading-title">INTERESTING FEATURES</h2>
                  <p className="heading-des">
                    Introducing fascinating features that shape the way we live,
                    work, and interact with the world. These innovations drive
                    progress, offering exciting possibilities and improvements
                    in various domains.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 wow fadeInUp pb-80">
                <div className="feature-box">
                  <div className="feature-icon">
                    <img
                      src="assets/images/feature-1.png"
                      alt="Safe & Secure"
                    />
                  </div>
                  <div className="feature-contain pt-25">
                    <a href="/feature" className="feature-title">
                      Safe &amp; Secure
                    </a>
                    <p className="feature-des">
                      Lorem ipsum dolor sit amet, consectetur adipi-sicing elit,
                      sed do eiusmod tempor incididunt ut labore et.Lorem ipsum
                      dolor sit amet, labore et.Lorem ipsum dolor sit amet.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp pb-80">
                <div className="feature-box">
                  <div className="feature-icon">
                    <a href="/feature">
                      <img
                        src="assets/images/feature-2.png"
                        alt="Early Bonus"
                      />
                    </a>
                  </div>
                  <div className="feature-contain pt-25">
                    <a href="/feature" className="feature-title">
                      Early Bonus
                    </a>
                    <p className="feature-des">
                      Lorem ipsum dolor sit amet, consectetur adipi-sicing elit,
                      sed do eiusmod tempor incididunt ut labore et.Lorem ipsum
                      dolor sit amet, labore et.Lorem ipsum dolor sit amet.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp pb-80">
                <div className="feature-box">
                  <div className="feature-icon">
                    <a href="/feature">
                      <img
                        src="assets/images/feature-3.png"
                        alt="Univarsal Access"
                      />
                    </a>
                  </div>
                  <div className="feature-contain pt-25">
                    <a href="/feature" className="feature-title">
                      Univarsal Access
                    </a>
                    <p className="feature-des">
                      Lorem ipsum dolor sit amet, consectetur adipi-sicing elit,
                      sed do eiusmod tempor incididunt ut labore et.Lorem ipsum
                      dolor sit amet, labore et.Lorem ipsum dolor sit amet.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp pb-80">
                <div className="feature-box">
                  <div className="feature-icon">
                    <a href="/feature">
                      <img
                        src="assets/images/feature-4.png"
                        alt="Secure Storage"
                      />
                    </a>
                  </div>
                  <div className="feature-contain pt-25">
                    <a href="/feature" className="feature-title">
                      Secure Storage
                    </a>
                    <p className="feature-des">
                      Lorem ipsum dolor sit amet, consectetur adipi-sicing elit,
                      sed do eiusmod tempor incididunt ut labore et.Lorem ipsum
                      dolor sit amet, labore et.Lorem ipsum dolor sit amet.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp pb-80">
                <div className="feature-box">
                  <div className="feature-icon">
                    <a href="/feature">
                      <img src="assets/images/feature-5.png" alt="Low Cost" />
                    </a>
                  </div>
                  <div className="feature-contain pt-25">
                    <a href="/feature" className="feature-title">
                      Low Cost
                    </a>
                    <p className="feature-des">
                      Lorem ipsum dolor sit amet, consectetur adipi-sicing elit,
                      sed do eiusmod tempor incididunt ut labore et.Lorem ipsum
                      dolor sit amet, labore et.Lorem ipsum dolor sit amet.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp pb-80">
                <div className="feature-box">
                  <div className="feature-icon">
                    <a href="/feature">
                      <img
                        src="assets/images/feature-6.png"
                        alt="Several Profit"
                      />
                    </a>
                  </div>
                  <div className="feature-contain pt-25">
                    <a href="/feature" className="feature-title">
                      Several Profit
                    </a>
                    <p className="feature-des">
                      Lorem ipsum dolor sit amet, consectetur adipi-sicing elit,
                      sed do eiusmod tempor incididunt ut labore et.Lorem ipsum
                      dolor sit amet, labore et.Lorem ipsum dolor sit amet.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="timeline lightskyblue ptb-100" id="roadmap">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <label className="sub-heading"> The Timeline</label>
                  <h2 className="heading-title">Roadmap</h2>
                  {/* <p className="heading-des">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam,
                  </p> */}
                </div>
              </div>
            </div>
            <div className="main-roadmap">
              <div className="row">
                <div className="col-md-12">
                  <div className="h-border wow fadeInLeft" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="roadmap-slider owl-carousel">
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title">2023 Q1</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>Team Creation & Resorce Acquisition</p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title">2023 Q3</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>Project Development</p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title">2023 Q3</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>Smart Contract and Building Development</p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title"> 2023 Q4</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>Pan Cake Swap listing</p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title">2023 Q4</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p> Deployment of Token Smart Contract </p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title"> 2024 Q1</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>Marketing and Development of Coin</p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title"> 2024 Q2</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p> Crypto Windows listing. CMC, COINGICO</p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title"> 2024 Q2</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>
                            {" "}
                            Exchanges and Crypto windows listing NFT Platform
                            Launch
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title"> 2024 Q3</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>Metaverse Gaming Platform Launching</p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title"> 2025 Q4</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>
                            GTC Express NFT Market Place & Agrigater, GTC Wallet
                            Launching"
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="roadmap wow fadeInLeft">
                      <div className="roadmap-box text-center">
                        <div className="date-title"> 2025 Q4</div>
                        <div className="map-graphic">
                          <div className="small-round">
                            <span />
                          </div>
                          <div className="v-row" />
                        </div>
                        <div className="roadmap-detail-box">
                          <p>GTC BOMBER</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="work-part lightskyblue ptb-100" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <h2 className="heading-title">METAVERSE</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center flex-align justify-center wow fadeInLeft">
                <div className="work-box">
                  <div className="work-box-bg" />
                  <img
                    src="assets/images/13.png"
                    className=""
                    alt="Work Process"
                  />
                </div>
              </div>
              <div className="col-md-6 flex-align wow fadeInRight">
                <div className="work-box">
                  <p className="work-des pb-20">
                    The metaverse offers immersive experiences through advanced
                    VR and AR technologies. Users can feel present in digital
                    environments and interact with them in real-time, blurring
                    the line between physical and digital realities.
                    Socialization is a central aspect of the metaverse. Users
                    can meet friends, attend events, collaborate on projects,
                    and participate in social experiences, fostering a sense of
                    community in a digital realm.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="work-part lightskyblue ptb-100" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <h2 className="heading-title">
                    TOTAL CONTROL WITH OUR TRANSACTION SYSTEM
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 flex-align wow fadeInRight">
                <div className="work-box">
                  <p className="work-des pb-20">
                    Text Transaction system will never ask you for your wallet
                    keys. Your funds will be securely held on a public listed
                    blockchain within a uniquely created keyless smart contract.
                    It signifies a state where individuals, businesses, and
                    institutions have complete oversight and authority over
                    their financial transactions.
                  </p>
                </div>
              </div>
              <div className="col-md-6 text-center flex-align justify-center wow fadeInLeft">
                <div className="work-box">
                  <div className="work-box-bg" />
                  <img
                    src="assets/images/12.png"
                    className=""
                    alt="Work Process"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="work-part lightskyblue ptb-100" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <h2 className="heading-title">NFT INFORMATION</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center flex-align justify-center wow fadeInLeft">
                <div className="work-box">
                  <div className="work-box-bg" />
                  <img
                    src="assets/images/18.png"
                    className=""
                    alt="Work Process"
                  />
                </div>
              </div>
              <div className="col-md-6 flex-align wow fadeInRight">
                <div className="work-box">
                  <ul className="check-list">
                    <li>
                      <a href="/blog-detail" className="blog-title">
                        Welcome to Globaltradings crypto:
                      </a>
                      <p>
                        Globaltradings is poised to revolutionize the world of
                        digital assets with its innovative offerings,
                        user-friendly interfaces and commitment to security.Your
                        journey with Globaltradings, you'll discover a platform
                        empowers you to invest, and engage with cryptocurrencies
                        like never before.
                      </p>
                    </li>
                    <li>
                      <a href="/blog-detail" className="blog-title">
                        Welcome are Globaltradings crypto collect next
                        generation:
                      </a>
                      <p>
                        Collecting the next generation of digital assets.
                        Globaltradings is at the forefront of innovation,
                        curating and providing access to the most promising and
                        cutting-edge cryptocurrencies.
                      </p>
                    </li>
                    <li>
                      <a href="/blog-detail" className="blog-title">
                        {"  "} What is nft?
                      </a>
                      <p>
                        Each NFT is one-of-a-kind, making it ideal for
                        representing digital or physical assets like art,
                        collectibles, music, virtual real estate, in-game items,
                        and more.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="work-part lightskyblue ptb-100" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <h2 className="heading-title">
                    Globaltradings NFT Market Place
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 flex-align wow fadeInRight">
                <div className="work-box">
                  <h3 className="work-process-title pb-25">
                    A world of unique digital collectibles
                  </h3>
                  <p className="work-des pb-20">
                    Join a thriving community of artists and collectors, as we
                    revolutionize the way you buy, sell, and trade NFTs. Stay
                    tuned for the launch of the Gravity Token NFT Marketplace,
                    where digital innovation meets boundless possibilities.
                  </p>
                </div>
              </div>
              <div className="col-md-6 text-center flex-align justify-center wow fadeInLeft">
                <div className="work-box">
                  <div className="work-box-bg" />
                  <img
                    src="assets/images/20.png"
                    className=""
                    alt="Work Process"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq-part pt-100">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  {/* <label className="sub-heading">Faqs</label> */}
                  <h2 className="heading-title">
                    OUR LATEST TECHNOLOGY & INTERESTING FEATURES
                  </h2>
                  <p className="heading-des">
                    Introducing fascinating features that shape the way we live,
                    work, and interact with the world. These innovations drive
                    progress, offering exciting possibilities and improvements
                    in various domains.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="tab-content">
                  <div className="tab-panel active" id="tab-1">
                    <div className="row">
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            Blockchain Applications: -
                          </a>
                          <p className="qus-des pt-10">
                            Blockchain technology has moved beyond
                            cryptocurrencies to offer transparency and security
                            in various industries. It's being used for supply
                            chain tracking, secure voting systems, and digital
                            identity management, promising a future of trust and
                            accountability.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            Green Technology: -{" "}
                          </a>
                          <p className="qus-des pt-10">
                            Sustainability is a key focus, with advancements in
                            renewable energy sources, electric vehicles, and
                            eco-friendly materials. Solar power, wind turbines,
                            and energy-efficient technologies play a vital role
                            in reducing our environmental footprint.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="team-part pt-100 pb-55">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  {/* <label className="sub-heading">Series of Globaltradings NFT's</label> */}
                  <h2 className="heading-title">
                    Series of Globaltradings NFT's
                  </h2>
                  {/* <p className="heading-des">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam,
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 wow fadeInLeft pb-45">
                <div className="team-box flex-align">
                  <div className="team-img">
                    <a href="#0">
                      <img src="assets/images/28.jpg" alt="member" />
                    </a>
                  </div>
                  <div className="team-des">
                    <a href="#0" className="member-name">
                      <strong>HORSE</strong>
                    </a>
                    <p className="member-des">
                      Horse Racing NFTS, where the ownership of extraordinary
                      digital collectibles technology.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pb-45 wow fadeInRight">
                <div className="team-box flex-align">
                  <div className="team-img">
                    <a href="#0">
                      {/* <img src="assets/images/51.jpg" alt="team member" /> */}
                      <img
                        src="https://is1-ssl.mzstatic.com/image/thumb/Purple111/v4/b1/32/23/b132234b-7b0d-ccad-8c1f-b792385aa30f/source/512x512bb.jpg"
                        alt="img2"
                      />
                    </a>
                  </div>
                  <div className="team-des">
                    <a href="#0" className="member-name">
                      <strong>CAR </strong>
                    </a>
                    <p className="member-des">
                      Car Racing NFTs. Imagine owning digital collectibles that
                      show off fast cars and thrilling races.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeInLeft pb-45">
                <div className="team-box flex-align">
                  <div className="team-img">
                    <a href="#0">
                      <img
                        src="assets/images/23.jpeg"
                        alt="team member"
                        style={{ marginTop: "14px" }}
                      />
                    </a>
                  </div>
                  <div className="team-des">
                    <a href="#0" className="member-name">
                      <strong>BULL </strong>
                    </a>
                    <p className="member-des">
                      Bull Race NFTS! We're taking the thrill of bull races and
                      blending it with the digital magic.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pb-45 wow fadeInRight">
                <div className="team-box flex-align">
                  <div className="team-img">
                    <a href="#0">
                      <img src="assets/images/24.jpeg" alt="team member" />
                    </a>
                  </div>
                  <div className="team-des">
                    <a href="#0" className="member-name">
                      <strong>BIKE</strong>
                    </a>
                    <p className="member-des">
                      Bike Sports NFTs, we're at the intersection of extreme
                      sports and blockchain innovation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeInLeft pb-45">
                <div className="team-box flex-align">
                  <div className="team-img">
                    <a href="#0">
                      <img src="assets/images/26.jpg" alt="team member" />
                    </a>
                  </div>
                  <div className="team-des">
                    <a href="#0" className="member-name">
                      <strong>DOG</strong>
                    </a>
                    <p className="member-des">
                      Dog Race NFT's, we're taking about awesomeness of dog
                      races and bringing into the digital world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="tokensale-part"
          className="token-sale parallax lightskyblue ptb-100"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <h2 className="heading-title">Tokenomics</h2>
                </div>
              </div>

              <div className="col-lg-12 flex-align justify-center-r">
                <div class="row">
                  <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text">Token Name</h6>
                      <h6 class="token-details-text token-detail-basic">
                        Globaltradings Coin
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text">Short Name</h6>
                      <h6 class="token-details-text token-detail-basic">
                        GTC Coin
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text ">Total Supply</h6>
                      <h6 class="token-details-text token-detail-basic">
                        15 M
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text">Instant Generate</h6>
                      <h6 class="token-details-text token-detail-basic">
                        4.5 M
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text">Lock</h6>
                      <h6 class="token-details-text token-detail-basic">
                        10.5 M (5 years)
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text">Decimal</h6>
                      <h6 class="token-details-text token-detail-basic">18</h6>
                    </div>
                  </div>
                  {/* <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text">Website Name</h6>
                      <h6 class="token-details-text token-detail-basic">
                      globaltradings.io
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mt-4 token-inner-body">
                    <div class="token-detail btn2">
                      <h6 class="token-details-text">Listing</h6>
                      <h6 class="token-details-text token-detail-basic">
                        $0.25 Cent
                      </h6>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="tokensale-part"
          className="token-sale parallax lightskyblue ptb-100 txt_color"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <h2 className="heading-title">Token Distribution</h2>
                </div>
              </div>

              <div className="col-lg-12 flex-align justify-center-r">
                <div class="row">
                  <img
                    src="assets/images/tokenomics-reldex.png"
                    alt="img2"
                    width="86%"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="blog-part ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  {/* <label className="sub-heading">news</label> */}
                  <h2 className="heading-title">We are launching</h2>
                  {/* <p className="heading-des">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam,
                  </p> */}
                </div>
              </div>
            </div>
            <div className="blog-slider owl-carousel">
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-1.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    NFT
                  </a>
                  {/* <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul> */}
                  <p className="blog-des">
                    Non-Fungible Token, is a revolutionary digital asset that
                    has gained widespread attention and adoption in recent
                    years. Unlike cryptocurrencies like Bitcoin or Ethereum,
                    which are fungible and can be exchanged on a one-to-one
                    basis, NFTs are unique digital tokens that represent
                    ownership of a specific digital item{" "}
                  </p>
                  {/* <a href="/blog-detail" className="read-more">
                    Read More
                  </a> */}
                </div>
              </div>
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-2.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Metaverse
                  </a>
                  {/* <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul> */}
                  <p>
                    The metaverse is a groundbreaking convergence of technology,
                    including augmented and virtual reality, blockchain,
                    artificial intelligence, and high-speed connectivity. It
                    aims to create a seamless digital ecosystem where users can
                    engage with a multitude of experiences, from gaming and
                    entertainment to education and commerce.{" "}
                  </p>
                  {/* <a href="/blog-detail" className="read-more">
                    Read More
                  </a> */}
                </div>
              </div>
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-3.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Gaming
                  </a>

                  <p>
                    Non-Fungible Token, is a revolutionary digital asset that
                    has gained widespread attention and adoption in recent
                    years. Unlike cryptocurrencies like Bitcoin or Ethereum,
                    which are fungible and can be exchanged on a one-to-one
                    basis, NFTs are unique digital tokens that represent
                    ownership of a specific digital item.{" "}
                  </p>
                  {/* <a href="/blog-detail" className="read-more">
                    Read More
                  </a> */}
                </div>
              </div>
              {/* <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-1.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Cryptocash is a clean, modern template clean
                  </a>
                  <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul>
                  <p className="blog-des">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
                  <a href="/blog-detail" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-2.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Cryptocash is a clean, modern template clean
                  </a>
                  <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
                  <a href="/blog-detail" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-3.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Cryptocash is a clean, modern template clean
                  </a>
                  <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
                  <a href="/blog-detail" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-1.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Cryptocash is a clean, modern template clean
                  </a>
                  <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul>
                  <p className="blog-des">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
                  <a href="/blog-detail" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-2.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Cryptocash is a clean, modern template clean
                  </a>
                  <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
                  <a href="/blog-detail" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
              <div className="blog-box wow fadeInUp">
                <div className="blog-img mb-15">
                  <a href="/blog-detail">
                    <img src="assets/images/blog-3.jpg" alt="blog" />
                  </a>
                </div>
                <div className="blog-des-box">
                  <a href="/blog-detail" className="blog-title">
                    Cryptocash is a clean, modern template clean
                  </a>
                  <ul className="blog-date">
                    <li>March 20,2021</li>
                    <li>by John Doe</li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
                  <a href="/blog-detail" className="read-more">
                    Read More
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* <section className="ico-apps parallax-2 lightskyblue pt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 wow fadeInLeft flex-bottom order-r-2">
                <div className="ico-apps-img w-100 text-center">
                  <img src="assets/images/ico-img.png" alt="mobile apps" />
                </div>
              </div>
              <div className="col-lg-6 wow fadeInRight pb-100 order-r-1">
                <div className="section-heading pb-20">
                  <label className="sub-heading">ico apps</label>
                  <h2 className="heading-title">ICO Mobile App</h2>
                  <p className="heading-des pb-20">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley also the leap into electronic
                    typesetting, remaining essentially unchanged.{" "}
                  </p>
                  <ul className="check-list mb-30">
                    <li>
                      <span>
                        <i className="fa fa-check" aria-hidden="true" />
                      </span>{" "}
                      <p>Crypto-news curation</p>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-check" aria-hidden="true" />
                      </span>{" "}
                      <p>Natural Language Understanding</p>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-check" aria-hidden="true" />
                      </span>{" "}
                      <p>
                        Et harum quidem rerum facilis est et expedita
                        distinctio.
                      </p>
                    </li>
                  </ul>
                  <a href="/" className="btn">
                    get the app now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="faq-part pt-100">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  {/* <label className="sub-heading">Faqs</label> */}
                  <h2 className="heading-title">
                    JOIN THE FASTEST GROWING ECOSYSTEM
                  </h2>
                  {/* <p className="heading-des">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam,
                  </p> */}
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <ul className="nav nav-tab Frequently-tabs pb-55">
                  <li>
                    <a className="tab-link active" href="0" data-tab="tab-1">
                      general
                    </a>
                  </li>
                  <li>
                    <a className="tab-link" href="0" data-tab="tab-2">
                      pre-ico &amp; ico
                    </a>
                  </li>
                  <li>
                    <a className="tab-link" href="0" data-tab="tab-3">
                      Tokens
                    </a>
                  </li>
                  <li>
                    <a className="tab-link" href="0" data-tab="tab-4">
                      client
                    </a>
                  </li>
                  <li>
                    <a className="tab-link" href="0" data-tab="tab-5">
                      legal
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12 wow fadeInUp">
                <div className="tab-content">
                  <div className="tab-panel active" id="tab-1">
                    <div className="row">
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            BSC COMPATIBILITY -
                          </a>
                          <p className="qus-des pt-10">
                            Binance Smart Chain (BSC) compatibility has become a
                            crucial aspect of the blockchain industry,
                            facilitating interoperability and expanding the
                            capabilities of the BSC ecosystem. BSC compatibility
                            primarily refers to the ability of BSC to work
                            seamlessly with other blockchain networks and
                            protocols.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            STRONG SECUTIRY -{" "}
                          </a>
                          <p className="qus-des pt-10">
                            Strong security is the cornerstone of trust in our
                            interconnected world. It encompasses a multifaceted
                            approach that includes encryption, authentication,
                            access control, and constant vigilance. In the realm
                            of technology
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            PAYMENT OPTION -
                          </a>
                          <p className="qus-des pt-10">
                            Digital payment options, including mobile wallets
                            like Apple Pay, Google Pay, and Samsung Pay, have
                            gained popularity for their ease of use. Users can
                            link their debit or credit cards to these wallets
                            and make contactless payments with their smartphones
                            or smartwatches. Additionally, peer-to-peer (P2P)
                            payment apps like Venmo and PayPal have made it
                            simple to transfer money to friends and family
                            instantly.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            TRANSPARENT INFORMATION -
                          </a>
                          <p className="qus-des pt-10">
                            Transparent information is a critical concept in
                            various aspects of our lives, from governance and
                            business to personal relationships and
                            decision-making. It refers to the clear, honest, and
                            readily accessible presentation of data, facts, and
                            details, without any intentional distortion,
                            concealment, or manipulation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-panel" id="tab-2">
                    <div className="row">
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            How can I participate in the ICO Token sale?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What is Ico Crypto?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What cryptocurrencies can I use to purchase?{" "}
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-panel" id="tab-3">
                    <div className="row">
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            How can I participate in the ICO Token sale?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What cryptocurrencies can I use to purchase?{" "}
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What is Ico Crypto?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-panel" id="tab-4">
                    <div className="row">
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            How can I participate in the ICO Token sale?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What cryptocurrencies can I use to purchase?{" "}
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            How do I benefit from the ICO Token?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What is Ico Crypto?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-panel" id="tab-5">
                    <div className="row">
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What cryptocurrencies can I use to purchase?{" "}
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            How do I benefit from the ICO Token?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 pb-65">
                        <div className="faq-tab">
                          <a href="/faq" className="qus-title">
                            What is Ico Crypto?
                          </a>
                          <p className="qus-des pt-10">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. remaining
                            essentially unchanged.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="bg-pattern lightskyblue ptb-100">
          <div className="container">
            <div className="footer">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-logo pb-25">
                    <a href="/">
                      <img
                        src="assets/images/logo.png"
                        alt="Cryptcon"
                        width="104px"
                      />
                    </a>
                  </div>
                  <div className="footer-icon">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/login/">
                          <i
                            class="fa-brands fa-facebook-f fa-lg"
                            style={{ color: "#636363" }}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/i/flow/login">
                          <i
                            class="fa-brands fa-twitter fa-lg"
                            style={{ color: "#636363" }}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/accounts/login/">
                          <i
                            class="fa-brands fa-instagram fa-lg"
                            style={{ color: "#636363" }}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://web.telegram.org/a/">
                          <i
                            class="fa-brands fa-telegram fa-lg"
                            style={{ color: "#636363" }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="footer-link">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      {/* <li><a href="#nft">NFT</a></li> */}
                      <li>
                        <a href="#tokensale-part">Token</a>
                      </li>
                      <li>
                        <a href="#roadmap">Roadmap</a>
                      </li>
                      <li>
                        <a
                          href="assets/Globaltradingswhitepaper.pdf"
                          target="blank"
                        >
                          Whitepaper
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="subscribe">
                    <div className="form-group">
                      <label>Conatct Us</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email Address"
                      />
                      <input
                        type="submit"
                        name="submit"
                        defaultValue="Subscribe"
                        className="submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              <div className="row">
                <div className="col-lg-6">
                  <p>
                    © All Rights Reserved. Designed By
                    <a href="/"> Globaltradings.info</a>
                  </p>
                </div>
                {/* <div className="col-lg-6">
                  <ul>
                    <li>
                      <a href="/">Terms &amp; Condition</a>
                    </li>
                    <li>
                      <a href="/">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
};
export default Home;
