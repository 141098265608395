import React, { useEffect, useState } from "react";

const ArrowBtn = () => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.scrollY > 509 ? setStatus(true) : setStatus(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);

  function topFunction() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      {status && (
        <button onClick={topFunction} title="Go To Top" id="myBtn">
          <i class="fa-solid fa-angle-up "></i>
        </button>
      )}
    </>
  );
};

export default ArrowBtn;
